import React from "react";
import "./TeamCard.css";

function TeamCard(props) {
  return (
    <>
      <div className="team-member-wrapper">
        <div className="team-member-image">
          <img src={props.image} alt={props.title} />
        </div>
        <div className="team-member-name">
          <h3>{props.name}</h3>
          <p>{props.title}</p>
          <div className="team-member-icons">
            <a href={props.linkedin} target="new">
              <i className="fa-brands fa-linkedin"></i>
            </a>
            <a href={props.trailhead} target="new">
              <i className="fa-brands fa-salesforce"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamCard;
